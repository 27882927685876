.sign-up {
    padding: 50px 0;
    @include flexCC;
    background: $greyBackground;
    height: auto;

    &-modal {
        width: 90%;
        margin: auto;
        background: $whiteColor;
        padding: $mobileBodyPadding;
        box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);

        h1 {
            font-size: 2rem;
            line-height: 1.27;
            font-weight: 300;
            color: #242424;
            margin-top: 10px;
            text-align: center;
        }
    }


    &-form {

        .form-control {
            position: relative;
            width: 100%;
            height: 55px;
            margin: 40px 0;
            @include flexC;

            .reveal-btn {
                position: absolute;
                right: 10px;
                font-size: 1.5rem;
            }
        }


        .conditions {

            .terms-condition {
                display: flex;
                margin: 15px 0;

                label {
                    margin-left: 15px
                }
            }

            a {
                text-decoration: underline;
            }
        }

        .account-submitBtn {
            display: flex;
            flex-direction: column-reverse;
            margin-top: 10px;


            p {
                text-decoration: underline;
                cursor: pointer;
                margin-top: 5px;
                text-align: center;
                margin-top: 25px
            }

            .btn {
                @include flexCB;
                min-width: 180px;
                position: relative;
                margin: auto;

                button {
                    width: 100%;

                    &:disabled {
                        background: $greyBackground;
                    }
                }
            }

        }

    }

}


@include for-phone-only {
    .sign-up {
        &-modal {
            padding: $smallestBodyPadding;

            h1 {
                font-size: 1.5rem;
            }
        }
    }
}

@include for-tablet-potrait-up {
    .sign-up {
        &-modal {
            width: 70%;

            h1 {
                font-size: 2.5rem;
            }
        }

        &-form {
            .account-submitBtn {
                margin-top: 30px;
            }

        }

    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .sign-up {

        &-modal {
            width: 490px;
        }

        &-form {
            .account-submitBtn {
                @include flexCB;
                flex-direction: row;

                p {
                    margin-bottom: 20px;
                }

            }

        }

    }
}

@include for-big-desktop-up {}