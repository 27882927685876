.nav {
    background: $primaryColor;
    padding: $mobileNavbarPadding;
    color: $whiteColor;
    z-index: 5;

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    &_logo {
        position: relative;
        height: 60px;
        width: 150px;
    }

    &_links {
        width: 80%;
        display: none;

        ul {
            display: flex;

            li {
                margin: 0 2rem;
                text-transform: capitalize;
                transition: $generalTransition;
                cursor: pointer;

                &:first-child {
                    margin-left: 0;
                }

                &:hover {
                    border-bottom: 1px solid $whiteColor;
                    transition: $generalTransition;
                }
            }

        }
    }


    .hambugger {
        @include flexCC;

        .icon {
            width: 25px;
            height: 25px;
        }
    }

}

.mobile_links {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 80px;
    background: $primaryColor;
    height: 100vh;
    width: 100%;
    padding: 50px 30px 0;
    transform: translateX(-100%);
    transition: $generalTransition;
    z-index: 8;

    ul {
        li {
            font-weight: 700;
            font-size: 30px;
            line-height: 31px;
            width: 100%;
            display: inline-block;
            text-transform: capitalize;
            margin-bottom: 25px;
            color: $whiteColor;
        }
    }

    &_btns {

        display: flex;
        margin-top: 60px;
        justify-content: space-between;
        width: 70%;

        button {
            margin: 0 0.5rem;
            width: 100%;
        }
    }


    &.show {
        transform: translateX(0);
    }

}


@include for-phone-only {
    .mobile_links {
        ul {
            li {
                font-size: 20px;
            }
        }

        &_btns {
            width: 80%;

            button {
                font-size: 0.6rem;
            }
        }

    }
}

@include for-tablet-potrait-up {
    .mobile_links {
        ul {
            li {
                font-size: 35px;
                margin-bottom: 45px;
            }
        }

        &_btns {
            margin-top: 180px;
            justify-content: space-between;
            width: 50%;

            button {
                width: 100%;
                font-size: 1.2rem;
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .nav {
        padding: $desktopNavbarPadding;
        position: static;

        &_links {
            @include flexCB;

            &_btns {

                button {
                    margin: 0 0.5rem;
                }
            }
        }

        .hambugger {
            display: none;
        }
    }

    .mobile_links {
        display: none;
    }
}

@include for-big-desktop-up {}