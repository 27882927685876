.withdrawal {
    background: $greyBackground;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    padding: $mobileBodyPadding;
}

@include for-phone-only {
    .withdrawal {
        padding: $smallestBodyPadding;
    }
}

@include for-tablet-potrait-up {}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .withdrawal {
        grid-template-columns: repeat(2, 1fr);
        padding: $mobileBodyPadding;
    }
}

@include for-big-desktop-up {}