.services {
    .header {
        padding: $mobileBodyPadding;

        h4 {
            color: $primaryColor;
            font-weight: 400;
            margin-bottom: 15px;
            text-align: center;
        }

        h2 {
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 40px;
        }


    }

    .investments {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        padding: $mobileBodyPadding;

        .item {
            text-align: center;
            @include flexCC;
            flex-direction: column;
            margin-bottom: 30px;

            .logo {
                position: relative;
                width: 130px;
                height: 130px;
                @include flexCC;
            }

            h3 {
                margin: 20px 0;
            }

            &:first-child {
                padding-bottom: 60px;
            }

        }
    }

    .client-support,
    .security {
        @include flexCB;
        padding: 40px 20px;
        background: #F3F3F4;
        flex-direction: column-reverse;

        .text {
            width: 100%;

            h4 {
                color: $primaryColor;
                margin: 20px 0;
            }

            h2 {
                font-weight: 300;
                font-size: 40px;
                line-height: 45px;
                margin-bottom: 30px;
            }
        }

        .image {
            position: relative;
            width: 100%;
            height: 320px;
        }

    }

    .security {
        background: $whiteColor;
    }

    .contact-box {
        padding: $mobileBodyPadding;
        background: #F3F3F4;

        p {
            font-size: 20px;

            span {
                color: $primaryColor;
            }
        }

        .btn {
            @include flexCC;

            button {
                margin-top: 30px;
                padding: 1rem 2rem;
            }

        }

    }

}


@include for-phone-only {
    .services {
        .header {
            padding: $smallestBodyPadding;

            h2 {
                font-size: 25px;
            }

        }

        .investments {
            padding: $smallestBodyPadding;

            .item {
                .logo {
                    width: 110px;
                    height: 110px;
                }

                h3 {
                    margin: 10px 0;
                }
            }
        }

        .client-support,
        .security {
            padding: $smallestBodyPadding;

            .text {
                h2 {
                    font-size: 30px;
                    line-height: 35px;
                }
            }

            .image {
                height: 220px;
            }

        }

        .contact-box {
            padding: $desktopBodyPadding;
        }
    }

}

@include for-tablet-potrait-up {
    .services {
        .header {
            text-align: center;

            h2 {
                margin-bottom: 20px;
            }
        }

        .investments {
            grid-template-columns: repeat(2, 1fr);

            .item {

                h3 {
                    margin: 15px 0;
                }
            }
        }

        .client-support,
        .security {
            flex-direction: row;

            .image {
                height: 350px;
            }

        }

        .contact-box {
            text-align: center;
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .services {
        .header {
            padding: $desktopBodyPadding;

            h2 {
                font-size: 40px;
            }

        }

        .investments {
            grid-template-columns: repeat(3, 1fr);
            padding: 20px 100px;

            .item {
                .logo {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        .client-support,
        .security {
            padding: 40px 100px;


            .image {
                height: 500px;
            }

        }


        .contact-box {
            padding: $desktopBodyPadding;
        }
    }
}

@include for-big-desktop-up {}