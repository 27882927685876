.successful-registration,
.successful-reset,
.withdrawal-request,
.verify-account,
.notification {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    @include flexCC;
    padding: $mobileBodyPadding;
    z-index: 66;

    .modal {
        background: $whiteColor;
        height: auto;
        padding: 40px 20px;
        @include flexCC;
        flex-direction: column;
        border-radius: 10px;
        text-align: center;

        .img {
            position: relative;
            height: 100px;
            width: 100px;
        }

        h2 {
            font-size: 20px;
            margin: 20px;
            color: $primaryColor;
        }

        .seed-phrase {
            background: $greyBackground;
            padding: 20px 10px;
            margin: 20px 0;
            width: 100%;
            @include flexCC;
            position: relative;
        }

        button {
            width: 140px;
        }

    }


}

.withdrawal-request {
    .warning {
        background: #F6D6DB;
        padding: 1rem;
    }
}

@include for-phone-only {
    .successful-registration {
        padding: $smallestBodyPadding;

        .modal {
            padding: 20px 20px;

            .img {
                height: 50px;
                width: 50px;
            }

            h2 {
                font-size: 20px;
                margin: 10px 0;
            }
        }


    }
}

@include for-tablet-potrait-up {}

@include for-tablet-landscape-up {}

@include for-desktop-up {

    .successful-registration,
    .successful-reset,
    .withdrawal-request,
    .verify-account,
    .notification {
        .modal {
            max-width: 500px;



            button {
                width: auto;
                padding: 10px 40px;
            }

        }


    }
}

@include for-big-desktop-up {}