    .sign-in,
    .forgot-password {
        display: flex;
        background: $greyBackground;
        padding: 60px 0;

        .form {
            width: 95%;
            margin: auto;
            padding: $mobileBodyPadding;
            background: $whiteColor;
            box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);



            h1 {
                font-size: 2.225rem;
                line-height: 1.27;
                font-weight: 300;
                color: #242424;
                text-align: center;
            }

            form {


                .form-control {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                }

                .error {
                    margin: 20px 0;
                    color: red;
                    display: none;

                    &.show-error {
                        display: block;
                    }

                    &.show-error .password-submitBtn {
                        margin-top: 0;
                    }
                }

                .password-submitBtn {
                    margin-top: 20px;
                    @include flexCB;

                    p {
                        text-decoration: underline;
                        cursor: pointer;
                    }


                    .btn {
                        @include flexCC;
                    }

                    button {
                        width: 140px;
                    }
                }

            }

        }

    }


    @include for-phone-only {

        .sign-in,
        .forgot-password {

            .form {

                h1 {
                    font-size: 1.9rem;
                }

                form {

                    .password-submitBtn {
                        button {
                            width: 100px;
                        }
                    }

                }

            }

        }
    }

    @include for-tablet-potrait-up {

        .sign-in,
        .forgot-password {

            .form {
                width: 60%;
                margin: 200px auto;

                form {
                    .password-submitBtn {
                        button {
                            width: 180px;
                        }
                    }

                }

            }

        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {

        .sign-in,
        .forgot-password {
            .form {
                width: 40%;
                margin: 50px auto 180px auto;

                h1 {
                    font-size: 2.625rem;
                }

                form {


                    .password-submitBtn {
                        button {
                            width: 160px;
                        }
                    }

                }

            }

        }

        .forgot-password {
            .form {
                margin: 50px auto 100px auto;
            }
        }
    }

    @include for-big-desktop-up {}