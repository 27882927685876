.guides {

    .block {
        padding: $mobileBodyPadding;
        margin-bottom: 30px;

        h1 {
            color: $primaryColor;
            font-weight: 700;
            font-size: 23px;
            margin-bottom: 10px;

            span {
                color: $darkText;
            }
        }
    }

    .assets {
        background: #E5E5E5;
        padding: $mobileBodyPadding;
        padding-top: 50px;
        padding-bottom: 100px;

        h2 {
            font-size: 28px;
            line-height: 48px;
            margin-bottom: 20px;
        }

        &-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;

            .card {
                display: flex;
                background: #fff;
                align-items: center;
                border-radius: 12px;
                flex-direction: column;
                padding: 40px 40px;

                .logo {
                    height: 120px;
                    width: 120px;
                    margin-bottom: 20px;
                    position: relative;
                }

                h3 {
                    color: #242424;
                    padding: 0px 40px;
                    font-size: 18px;
                    margin-bottom: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }

}

@include for-phone-only {
    .guides {

        .block {
            h1 {
                font-size: 20px;
            }
        }

        .assets {

            h2 {
                font-size: 22px;
                line-height: 28px;
            }

            &-container {
                .card {
                    padding: 40px 20px;

                    .logo {
                        height: 100px;
                        width: 100px;
                    }

                    h3 {
                        padding: 0px 30px;
                        font-size: 1rem;
                    }
                }
            }
        }

    }
}

@include for-tablet-potrait-up {
    .guides {
        .block {
            margin-bottom: 50px;

            h1 {
                font-size: 38px;
                margin-bottom: 15px;
            }
        }

        .assets {
            h2 {
                font-size: 35px;
                margin-bottom: 40px;
            }

            &-container {
                grid-template-columns: repeat(2, 1fr);

                .card {
                    min-height: 450px;
                    position: relative;

                    h3 {
                        padding: 0px 20px;
                        font-size: 20px;
                    }
                }
            }
        }

    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .guides {

        .block {
            padding: $desktopBodyPadding;

            p {
                padding-right: 400px
            }
        }

        .assets {
            padding: $desktopBodyPadding;

            h2 {
                font-size: 32px;
                margin-bottom: 40px;
            }

            &-container {
                grid-template-columns: repeat(3, 1fr);

                .card {
                    min-height: 400px;
                }
            }
        }
    }
}

@include for-big-desktop-up {}