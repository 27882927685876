    .contact {
        padding: $mobileBodyPadding;

        h1 {
            font-size: 25px;
            color: $primaryColor;
            font-weight: 500;
            text-align: center;

            span {
                color: $darkText;
                font-style: italic;
                font-weight: 200;
            }
        }

        .methods {
            @include flexCC;
            flex-direction: column;
            margin-top: 30px;

            .item {
                margin: 20px 0px;
                @include flexCC;
                flex-direction: column;

                .image {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    color: $primaryColor;
                    margin-bottom: 15px;
                }

                button {
                    min-width: 200px;
                }
            }
        }

        .form-container {
            margin-top: 40px;

            h3 {
                font-size: 25px;
                font-weight: 400;
            }

            form {

                .form-control {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    margin: 20px 0;
                    display: flex;
                    align-items: center;
                }

                textarea {
                    width: 100%;
                    height: 30vh;
                    padding: 1rem;
                    font-size: 16px;
                }

                .submitBtn {
                    @include flexCC;

                    button {
                        width: 200px;
                        margin-top: 20px;
                    }
                }

            }
        }
    }

    @include for-phone-only {
        .contact {
            padding: $smallestBodyPadding;

            h1 {
                font-size: 18px;
            }
        }
    }

    @include for-tablet-potrait-up {
        .contact {
            padding: $mobileBodyPadding;
            padding-top: 40px;
            padding-bottom: 40px;

            h1 {
                font-size: 40px;
            }

            .methods {
                flex-direction: row;

                .item {
                    margin: 0px 30px;
                    flex-direction: column;
                }
            }
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {
        .contact {
            padding: $desktopBodyPadding;

            h1 {
                font-size: 40px;
                text-align: start;
            }


            .methods {
                flex-direction: row;
                margin-top: 50px;

                .item {
                    margin: 0 50px;

                    .image {
                        margin-bottom: 30px;
                    }
                }
            }

            .form-container {
                form {
                    .submitBtn {
                        justify-content: flex-start;

                    }

                }
            }
        }
    }

    @include for-big-desktop-up {}