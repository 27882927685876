.dashboard {
    background: $greyBackground;
    padding-top: 40px;

    .mini-charts {
        padding: $mobileBodyPadding;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    .technical-chart {
        padding: $mobileBodyPadding;
        padding-top: 10px;
    }

    .advanced-chart {
        padding: $mobileBodyPadding;
        padding-top: 10px;
        height: 80vh;
        display: none;
    }

    .wallet-container {
        .loader {
            margin: auto;
            background: transparent !important;
            height: 50px;
            width: 50px;
        }
    }


}

@include for-phone-only {
    .dashboard {
        .mini-charts {
            padding: $smallestBodyPadding;
        }

        .technical-chart {
            padding: $smallestBodyPadding;
        }
    }
}

@include for-tablet-potrait-up {
    .dashboard {
        .mini-charts {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .dashboard {
        background: $greyBackground;
        padding-top: 40px;

        .mini-charts {
            padding: $mobileBodyPadding;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        .technical-chart {
            padding: $desktopBodyPadding;
            padding-top: 10px;
        }

        .advanced-chart {
            display: block;
        }

    }
}

@include for-big-desktop-up {}